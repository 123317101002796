function drawButterflies() {
  var maxTime = 50;
  var maxTimePink = 40;
  var butterflies = document.getElementsByClassName("butterflies-wrapper")[0];
  var singleButterfly = document.getElementById("butterfly");
  var pinkButterfly = document.getElementById("butterflyPink");
  var g = document.getElementsByClassName("butterflies-wrapper")[0];
  var width = g.clientWidth;
  var height = g.clientHeight;
  var initialTop = butterflies.clientHeight * -1;
  var butterflyCount = 4;
  var pinkButterflyCount = 5;

  for (var i = 0; i < butterflyCount; i++) {
    var ypos = Math.round(Math.random() * height);

    var createButterfly = document.createElement("div");
    createButterfly.innerHTML = singleButterfly.outerHTML;

    var speed = 15400 * (Math.random(100, 200) * maxTime + 1);
    createButterfly.setAttribute(
      "class",
      "createbutterfly createbutterfly" + (3 - Math.floor(speed / 1000 / 8))
    );

    var speedDelay = 10000;

    butterflies.appendChild(createButterfly);
    createButterfly.animate(
      [
        {
          transform: "translate3d(" + width + "px, " + initialTop + "px, 0)",
        },
        {
          transform:
            "translate3d(-" +
            Math.random() * (width - 50) +
            "px, " +
            (ypos + Math.round(500, 2500)) +
            "px, 0)",
        },
      ],
      {
        delay: Math.random() * -speed,
        duration: speedDelay,
        iterations: 100,
      }
    );
  }

  //pink butterfly
  for (var j = 0; j < pinkButterflyCount; j++) {
    var yposPink = Math.round(Math.random() * height);

    var createButterflyPink = document.createElement("div");
    createButterflyPink.innerHTML = pinkButterfly.outerHTML;

    var speedPink = 1000 * (Math.random() * maxTimePink + 1);
    createButterflyPink.setAttribute(
      "class",
      "createbutterfly createbutterflyPink" +
        (3 - Math.floor(speedPink / 1000 / 8))
    );

    var speedDelayPink = 4000;

    butterflies.appendChild(createButterflyPink);
    createButterflyPink.animate(
      [
        {
          transform:
            "translate3d(" +
            width +
            "px, " +
            Math.min(yposPink, height - 50) +
            "px, 0)",
        },
        {
          transform:
            "translate3d(-" +
            Math.random() * (width - 50) +
            "px, " +
            initialTop +
            "px, 0)",
        },
      ],
      {
        delay: Math.random() * -speedPink,
        duration: speedDelayPink,
        iterations: 100,
      }
    );
  }
}

export default drawButterflies;
