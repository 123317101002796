import "../styles/index.scss";
import drawButterflies from "./butterflies";
import { getWeek, weekShouldBeActive } from "./utilities";

var startWeek = 10;

var calendarWrapper = document.getElementById("calendar");
var calendarItemTemplate = document.getElementsByTagName("template")[0];
calendarItemTemplate = calendarItemTemplate.content.querySelector("div");

// Titles provided by Vlinderstichting
var titles = [
  "Klik voor deze week!",
  "Bekijk wat er deze week op de planning staat",
  "Maak het hokje open",
  "Klik hier",
];

// Get current date and check current week based on start week
var currentDate = new Date();
var currentWeek = getWeek(currentDate) - (startWeek - 1);
currentDate = new Date();

// Loop through the 40 weeks and create a new calendar item for each week
for (var weekNumber = 1; weekNumber <= 40; weekNumber++) {
  var newCalendarItem = createCalendarItem(
    weekNumber,
    currentWeek,
    currentDate
  );

  calendarWrapper.appendChild(newCalendarItem);
}

// Function to create a new calendar item for the given week number
function createCalendarItem(week, currentWeek) {
  // Create a new calendar item by cloning the template
  var newCalendarItem = document.importNode(calendarItemTemplate, true);

  // Get the door wrapper and create a new image element
  var doorWrapper = newCalendarItem.getElementsByClassName("door")[0];
  var elem = document.createElement("img");

  if (weekShouldBeActive(week, currentWeek, currentDate)) {
    elem.setAttribute("src", "/public/images/week" + week + ".png");
    elem.setAttribute("alt", "Week " + week);

    // If the week should be active, set the image source, add a link to the Vlinderstichting website, and set the title text
    var anchor = newCalendarItem.getElementsByTagName("a")[0];
    anchor.href = "https://www.vlinderstichting.nl/week-" + week;
    anchor.target = "_blank";

    var text = newCalendarItem.getElementsByTagName("h3")[0];
    text.innerText = titles[week % 4];

    newCalendarItem.classList.add("active");

    // If this is the current week, add a butterflies wrapper element and a "current" class to the calendar item
    // Also check if's wednessday or later
    if (
      (week === currentWeek &&
        (currentDate.getDay() === 0 || currentDate.getDay() >= 3)) ||
      (currentDate.getDay() > 0 &&
        currentDate.getDay() < 3 &&
        week === currentWeek - 1)
    ) {
      var butterfliesWrapper = document.createElement("div");
      butterfliesWrapper.classList.add("butterflies-wrapper");
      newCalendarItem.appendChild(butterfliesWrapper);
      newCalendarItem.classList.add("current");
    }

    // Append this week's image to the door wrapper
    doorWrapper.appendChild(elem);
  } else {
    // If the week should not be active, set the image source to the "week0" image (placeholder image)
    elem.setAttribute("src", "/public/images/week0.png");
    elem.setAttribute("alt", "Week 0");
    doorWrapper.appendChild(elem);
  }
  return newCalendarItem;
}

// Draw butterflies in the active week element
drawButterflies();
