export function getWeek(date) {
  const dateCopy = date;
  dateCopy.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  dateCopy.setDate(dateCopy.getDate() + 3 - ((dateCopy.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(dateCopy.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((dateCopy.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
}

// Check if the given week should be active
export function weekShouldBeActive(week, currentWeek, currentDate) {
  if (currentDate.getDay() === 0 || currentDate.getDay() >= 3) {
    return currentWeek >= week;
  }
  return currentWeek - 1 >= week;
}
